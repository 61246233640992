import React, { useContext, useEffect, useState, useRef } from "react";

const Slider = () => {
    useEffect(() => {
    const fadeElements = document.getElementsByClassName('scrollFade');

    const scrollFade = () => {
      const viewportBottom = window.scrollY + window.innerHeight;
      for (let index = 0; index < fadeElements.length; index++) {
        const element = fadeElements[index];
        const rect = element.getBoundingClientRect();
        const elementFourth = rect.height / 4;
        const fadeInPoint = window.innerHeight - elementFourth;
        const fadeOutPoint = -(rect.height / 2);
        
        if (rect.top <= fadeInPoint) {
          element.classList.add('scrollFade--visible');
          element.classList.add('scrollFade--animate');
          element.classList.remove('scrollFade--hidden');
        } else {
          element.classList.remove('scrollFade--visible');
          element.classList.add('scrollFade--hidden');
        }
        
        if (rect.top <= fadeOutPoint) {
          element.classList.remove('scrollFade--visible');
          element.classList.add('scrollFade--hidden');
        }
      }
    };

    document.addEventListener('scroll', scrollFade);
    window.addEventListener('resize', scrollFade);
    scrollFade(); // Initial call

    return () => {
      document.removeEventListener('scroll', scrollFade);
      window.removeEventListener('resize', scrollFade);
    };
  }, []); // Empty dependency array to ensure the effect runs only once
    return (
    <>
        <section className="sliderHero padSec h100vh text-center scrollFade">
            <div className="container">
                <p className="pHighlight fade1">
                    <span></span>Bitcoin Regular With Lowest Commission
                </p>
                <h1 className='fade2'>The Easiest Way To Buy, Sell And Trade Crypto Currency Coins</h1>
                <div className="row slideMulti justify-content-center">
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div className="snBox fade3 boxanimation1">
                            <img src="latestimages/home/exchange.png" className="img-fluid snBoxImage" />
                            <h3>Btrl</h3>
                            <h4>Btrl Exchange</h4>
                            <p>The fast, secure & most populer crypto exchange</p>
                            <div className="btnsHere">
                                
                                <a href="/exchange" className="btn"><i className="fa-solid fa-right-left"></i> Visit on Exchange</a>
                            </div>
                            <div className="bgbox">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div className="snBox fade4 boxanimation2">
                            <img src="latestimages/home/5331160.webp" className="img-fluid snBoxImage" />
                            <h3>Pension Rank</h3>
                            <p>Secure Your Future with Confidence</p>
                            <div className="btnsHere">
                                <a href="/pension-rank" className="btn btns"><i className="fa-solid fa-right-left"></i> Visit on Pension Rank</a>
                            </div>
                            <div className="bgbox">
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div className="snBox fade4 boxanimation2">
                            <img src="latestimages/home/gift.png" className="img-fluid snBoxImage" />
                            <h3>Crorepati Lucky Draw 2.0</h3>
                            <p>An Idea Which Can Make You Rich</p>
                            <div className="btnsHere">
                                <a href="/crorepati-lucky-draw" className="btn btns"><i className="fa-solid fa-right-left"></i> Visit on Lucky Draw</a>
                            </div>
                            <div className="bgbox">
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div className="snBox fade5 boxanimation3">
                            <img src="latestimages/home/support.png" className="img-fluid snBoxImage" />
                            <h3>Contact with</h3>
                            <h4>Support Team</h4>
                            <p>24/7 online customer service</p>
                            <div className="btnsHere">
                                <a href="/contact-us" className="btn"><i className="fa-solid fa-right-left"></i> Contact Us</a>
                            </div>
                            <div className="bgbox">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
   );
};

export default Slider;