/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import styles from "./module.scss";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";

import PaginationBox from "./PaginationBox/PaginationBox.js";
import FilterComponent from "./FilterComponent.js"
import Context from "../../Context/Context";
//import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars

import Table from 'react-bootstrap/Table';
import Modal from 'react-modal';

import {
  sendRequest,
  refreshToken,
  shortener
} from "../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Rank = () => {
 
  const { states } = useContext(Context);
  const [allData, setAllData] = useState();
  const [activePage, setActivePage] = useState(localStorage.getItem("RankPages") && localStorage.getItem("RankPages")!==""  ? parseInt(localStorage.getItem("RankPages")):1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [activeValue, setActiveValue] = useState("Rank");
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;

  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState('');
  const [search, setSearch] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const handleClear = () => {
    if (filterText) {
     setResetPaginationToggle(!resetPaginationToggle);
     setSearch("");
      setFilterText('');
    }
  }
  useEffect(()=>{
    console.log("activePage => ",activePage);
  
   const getRankData = async () => {
    const oohres = await sendRequest(
                          {
                            
                            start: activePage > 1 ? activePage * 10 - 10 : 0,
                            //start: 1,
                            limit: "10",
                             filter:search
                          },
                          "get-pension-rank"
                        );
                        console.log("oohres => ",oohres);
    setAllData(oohres.data)
    setTotalRecord(oohres.totalRecords)
  }
  getRankData();
  },[activePage,search, resetPaginationToggle])
  const searchHandler=()=>{
      setSearch(filterText)
  }


  const [copyaddress, setCopyaddress] = useState('00');
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  
  function imagePopup(e) {
      navigator.clipboard.writeText(e.currentTarget.title);
      setIsOpen(true);
      setCopyaddress(" " + e.currentTarget.title);
  }

  function closeModal() {
      setIsOpen(false);
  }

  return (
    <div className={` ligtMode`}>
      <Navbar />
      <div className='container relative  pt-4'>
      <div className={` historyTable  p-relative`} >
        <div class="stakeMain ">
        <h1 className={` text-center mt-3 mb-3`}>
         Pension Rank
        </h1>
      </div>
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} onSearch={ searchHandler} search={search}/>
          <div class="thLook tableLook d-flex justify-content-between align-items-center flex-wrap">
              <div>User</div>
              <div>User Full Name</div>
              <div>Direct Team</div>
              <div>Rank</div>
              <div>Eligiblity</div>
          </div>
            
              {allData && allData?.length > 0  && allData?.map((item, bi) => (
                <div class="tdLook tableLook d-flex justify-content-between align-items-center flex-wrap" key={bi}>
                  <div class="usertd" title={item.profile_image ? item.path+item.profile_image : "https://btrlexchange.com/backend/uploads/userdata/profile/Screenshot_20241116_202138_Gallery1.jpg"} onClick={imagePopup} >
                        <img src={item.profile_image ? item.path+item.profile_image : "/user.jpg"} className="img-fluid rankuserImgLatest rankuser rankuser-sm "/>
                        {item.UID} 
                  </div>
                  <div ><p class="mobShow">User Full Name</p>{item.name}</div>
                  <div ><p class="mobShow">Direct Team</p>{item.team_count}</div>
                  <div ><p class="mobShow">Rank</p>{item.rank}</div>
                  <div ><p class="mobShow">Eligiblity</p>{item.pension}</div>
                </div>
              ))}
          <div className={` flex-between`}>
        <div></div>
        <div className=''>
          {allData && (
            <PaginationBox
              
              activePage={activePage}
              setActivePage={setActivePage}
              _totalPage={totalRecord}
            />
          )}
        </div>
      </div>
        </div>
      </div>
      <Footer />


  <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
      <div className="popupcenter text-center p-2">
        <div class="userImg">
          <img src={copyaddress} class="img-fluid" />
        </div>
        
        <span className="border-btn-small d-block btns "><button className="btn small btn-primary " onClick={closeModal}>Close</button></span>
        </div>
      </Modal>
    </div>


  );
};

export default Rank;
