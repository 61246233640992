/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import styles from "./PensionPage.module.scss";
import { AiTwotoneCopy } from "react-icons/ai";

import nodata from "../../../Assets/nodata.png";
import { authRequestNb, refreshToken } from "../../../Helper/helper";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";
import DataTable, { createTheme } from 'react-data-table-component';
// import FilterComponent from "./FilterComponent";
// import FilterSelectComponent from "./FilterSelectComponent";
const rData = [];

const PensionPage = () => {
  const [data, setData] = useState();
  const [levels, setLevels] = useState([]);
  const [booster, setBooster] = useState();
  const [superBooster, setSuperBooster] = useState();
  const [searchData, setSearchData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [boosterBtext, setBoosterBtext] = useState("Activate");
  const [superBoosterBtext, setSuperBoosterBtext] = useState("Activate");
  const { states } = useContext(Context);

  const dmode = states.dmode;



  const copiedHandler = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const boosterHandler = async () => {
    setBoosterBtext("Please Wait...");
    const res = await authRequestNb("activate-booster-plan");
     res && swal(res.message);
    if (res.status === 200) {
      setBooster(true);
    }
  };
  const superBoosterHandler = async () => {
    setSuperBoosterBtext("Please Wait...");
    const res = await authRequestNb("activate-super-booster-plan");
     res && swal(res.message);
    if (res.status === 200) {
      setSuperBooster(true);
    }
  };

  useEffect(() => {
    (async () => {
      
      const res = await authRequestNb("pension-plan");
      refreshToken(res.refresh_token);
      setData(res.data);
      setBooster(res.data.self_details.booster_staus === "1");
      setSuperBooster(res.data.self_details.super_booster_staus === "1");
    
    })();
  }, []);


 
  const [pending, setPending] = React.useState(true);
	
  useEffect(() => {
		const timeout = setTimeout(() => {
			setLevels(Object.entries(data?.pension_plan))
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [data]);


  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });
  useEffect(()=>{
    //console.log("data",data);
  },[data])
  const handleDownload = () => {
    fetch('https://btrlexchange.com/backend/plan/btrl-exchange-refer-earn-plan-v3.pdf')
      .then((response) => response.blob()) 
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'btrl-exchange-refer-earn-plan-v3.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Error downloading the file:', error));
  };
  return (
    <div className={`${styles.reff_l}  ligtMode`}>
      <Navbar />
      <div className= 'stakeMain '>
      <div className= {`${styles.reff} container`}>
        <div className='row align-items-center'>
          <div className="col-sm-7">
          <div className={styles.impn}>
            <h1>Refer & More</h1>
             <div className="row align-items-center">
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>50%</h4>
                <p>of lifetime trading fee like BTC, ETH & BTRL etc</p>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Refer & Earn Plan
                  <p>
                    {/* <a className="btnbox" href="https://btrlexchange.com/backend/plan/btrl-exchange-refer-earn-plan-v3.pdf">View Now</a> */}
                    <a
                    href="#"
                      className="btnbox"
                      onClick={handleDownload}
                    >
                      Download Now
                    </a>
                  </p></h4>
              </div>
              </div>
            </div>
            {data && (
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Booster Plan @1000</h4>
                <p>{booster ? (
                  <span className='active pl-2 btnbox'  style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}>Active</span>
                ) : (
                  <>
                  {boosterBtext==="Activate"?(
                    <span 
                    type="button"
                    onClick={boosterHandler}
                    style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {boosterBtext}
                  </span>
                  ):(
                    <span 
                    type="button"
                   
                    style={{ backgroundColor: booster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {boosterBtext}
                  </span>
                  )}
                  </>
                )}</p>
              </div>
              </div>
            </div>
             )}
            {data && (
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <h4>Super Booster Plan @5000</h4>
                <p>{superBooster ? (
                  <span className='active pl-2 btnbox'  style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}>Active</span>
                ) : (
                  <>
                  {superBoosterBtext==="Activate"?(
                    <span 
                    type="button"
                    onClick={superBoosterHandler}
                    style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {superBoosterBtext}
                  </span>
                  ):(
                    <span 
                    type="button"
                   
                    style={{ backgroundColor: superBooster ? "#2baa85" : "#7957d4" }}
                     className={`${styles.booster} btn pl-2 btnbox`}
                  >
                    {superBoosterBtext}
                  </span>
                  )}
                  </>
                )}</p>
              </div>
              </div>
            </div>
             )}
            </div>
            
          </div>

          <div className={`${styles.il}  column inviteFull`}>
            <div className="row">
            <div className="col-sm-7">
            <h4>Invite Link</h4>
            <h2 className="flex inviteBox">
              {data?.self_details.url}{" "}
              <button className="flex-center">
                <CopyToClipboard
                  text={data?.self_details.url}
                  onCopy={copiedHandler}
                >
                  <AiTwotoneCopy />
                </CopyToClipboard>
                {copied && <span>Copied!</span>}
              </button>
            </h2>
          </div>
            <div className="col-sm-5 sponerHere">
              <h4>Sponser</h4>
            <p>
              {data?.sponser_details.sponser_id} ({data?.sponser_details.name},{" "}
              <a
                href={`https://wa.me/${data?.sponser_details.mobile}`}
                target="_blank"
                rel="noreferrer"
              >
                <span>{data?.sponser_details.mobile}</span>
              </a>
              )
            </p>
            <p className="s">
              Referral ID - {data?.self_details.refferal_id}
            </p>
            </div>
          </div>
          </div>
        </div>
      <div className="col-sm-5">
        
        <div className="text-center imgmobhide">
          <img src="/5453369_2784129.png" className="img-fluid" />
        </div>
      </div>
      </div>
        </div>

      </div>
        <div className={`${styles.reff} m-0 pb-5`}>
          <div className="container">

        <h2>
        Permanent Pension Plan (Direct Team With 1000 ₹ Or Above)
        </h2>
          <div className="row">
            <div className="col-sm-7">
             
             <div className={`${styles.boxes}`}>
                {levels.map(([level, details], i) => (
                  <div key={i} className={`${styles.box} snPlan`}>
                    <h1>{level}@{details.teamCount}</h1>
                    <h1>
                    ₹ {details.monthlyPension}
                    </h1>
                   
                  </div>
                ))}
              </div>
            </div>
            <div className="col-sm-5 ">
              <div className="tableDesign">
                <div className={`custableresponsive table-responsive outerBorder specialtable `} >
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>My Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rank</td>
                      <td><b>{data?.pension_rank!==""?data?.pension_rank:"N/A"}</b></td>
                    </tr>
                    <tr>
                      <td>Team Count</td>
                      <td><b>{data?.pension_team_count}</b></td>
                    </tr>
                    <tr>
                      <td>Monthly Pension</td>
                      <td><b>{data?.pension_pension}</b></td>
                    </tr>
                    <tr>
                      <td>ICard</td>
                      <td><b>{(superBooster && data?.pension_rank)?
                        <>
                         <a
                        href="/id-card"
                          className="btnbox"
                          
                        >
                          View
                        </a>
                        </>:<>N/A</>}</b></td>
                    </tr>
                    
                  </tbody>
                </table>
                </div>
            </div>
            </div>
    </div>

   
    </div>
    </div>

      <Footer />
    </div>
  );
};

export default PensionPage;
