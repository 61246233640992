import React, { useContext, useState, useEffect } from "react";


import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import wallet_i from "../../Assets/wallet_login_register.png";
import { BsEyeFill } from "react-icons/bs";
/* import login from "../../../Assets/login.svg"; */
import login from "../../Assets/login.svg";
import styles from "./SignIn.module.scss";
  
  import { sendRequest, vpassword,validateEmail} from "../../Helper/helper";
import validator from "validator";
import swal from "sweetalert";
import jwt_decode from "jwt-decode";
import ReCaptcha from "../../Components/ReCaptcha/ReCaptcha";
import { Link, useNavigate } from "react-router-dom";
import { MdSecurity } from "react-icons/md";
import { BsPersonCheckFill } from "react-icons/bs"
const SignIn = ({ _redirect = "exchange",userposition }) => {
  //console.log("location.state?.from",userposition);
  const [sp, setSp] = useState(false);
  const [_alert, set_alert] = useState({});
  const [submitBtn, setSubmitBtn] = useState(false);
  const [lEmail, setLEmail] = useState(false);
  const [lPassword, setLPasssword] = useState(false);
  const [lCode, setLCode] = useState(false);
  const [lOtp, setLOtp] = useState(false);
  
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [twoFaStatus, setTwoFaStatus] = useState(false);
  const [resendLink, setResendLink] = useState(false);
  const [resendLinkEmail, setResendLinkEmail] = useState({});
  const [captcha, setCaptcha] = useState();
  const [otp, setOtp] = useState("");
  const [otpButtonLabel, setOtpButtonLabel] = useState("Send OTP");
   const [otpSent, setOtpSent] = useState(false);
  const [validate, setValidate] = useState({
    email: "",
    password: "",
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { states } = useContext(Context);
  const spHandler = () => (sp ? setSp(false) : setSp(true));

  const alertHandler = (key, val) => {
    let change = _alert;
    change[key] = val;
    set_alert({ ...change });
  };

  const validateHandler = (key, val) => {
    let change = validate;
    change[key] = val;
    setValidate({ ...change });
  };

  const getData = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
    set_alert({});
    if (key === "email") {
      setOtpSent(false);
      setOtpButtonLabel("Send OTP");
      validateHandler(key, validator.isEmail(val));
    } else if (key === "password") {
      validateHandler(key, vpassword(val));
    } else if (key === "otp") {
      validateHandler(key, (val));
    }
  };
  const handleSendOtp = async () => {
    if (formData.email && validateEmail(formData.email)) {
      setOtpButtonLabel("Sending...");
      const response = await sendRequest(
        { email: formData.email },
        "send-login-otp",
        "post"
      );
      if (response.status === 200) {
        swal("OTP sent to your email address!");
        setOtpSent(true);
        setOtpButtonLabel("OTP Sent");
      } else {
        setOtpButtonLabel("Send OTP");
        swal(response.message || "Failed to send OTP.");
      }
    } else {
      if(!formData.email && !validate.email){
        alertHandler("email", "Please enter email !");
      }
      validateHandler((prev) => ({ ...prev, email: "Invalid email." }));
    }
  };
  const navigate = useNavigate();
  const resendLinkHandler = async ()=>{
  const res = await sendRequest(
    {
      email: formData.email,
    },
    "resend-verification-email",
    "post"
  );
  if (res && res.status === 200) {
    setResendLink(false);
  }
  swal(res.message);
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate.email && formData.email && formData.password && formData.otp) {
      setLEmail(formData.email)
      setLPasssword(formData.password)
      setLOtp(formData.otp)
      setSubmitBtn(true);
      const res = await sendRequest(
        {
          email: formData.email,
          password: formData.password,
          otp: formData.otp,
          "g-recaptcha-response": captcha, 
        },
        "login",
        "post"
      );
      try {
        res && setSubmitBtn(false);
        setResetCaptcha(true);
       
        if (res.status === 200) {
          const decoded = jwt_decode(res.data);
        //  console.log("decoded",decoded);
         localStorage.setItem("hideZero", decoded.hideZeroStatus);
         localStorage.setItem("st", decoded.st);
         document.querySelector("form").reset();
         sessionStorage.setItem("user", JSON.stringify(res.data));
          localStorage.removeItem("Pages");
          
          if (userposition.previous && userposition.previous === '/btrl-game') {
            navigate('/btrl-game'); // Set the desired page as the redirect target
           } else if (userposition.previous && userposition.previous === '/crorepati-lucky-draw') {
            navigate('/crorepati-lucky-draw'); // Set the desired page as the redirect target
          } else {
            navigate(`/${_redirect}`); // Set the default dashboard page as the redirect target
          }
        
        } else if (res.status === 402) {
          swal(res.message);
        } else if (res.status===405){
          swal(res.message);
          setResendLink(true);
          setResendLinkEmail({"email":formData.email});
        }else if(res.status===406){
          setTwoFaStatus(true);
        }else {
          swal(res.message);
        }
      } catch (err) {
        if (err) {
          //swal(res.message, res.data);
          swal(res.message?res.message:"Something went wrong. Try again.");
        }
      }
    }else{
      if(!formData.email && !validate.email){
        alertHandler("email", "Please enter email !");
      }
      if(!formData.password){
        alertHandler("password", "Please enter password !");
      }
      if(!formData.otp){
        alertHandler("otp", "Please enter otp !");
      }
    }
  };
const tFAVerifyHandler = async()=>{
  if(lCode){
    const res = await sendRequest(
      {
        email: lEmail,
        password: lPassword,
        "code": lCode, 
      },
      "verify-code",
      "post"
    );
    try {
      if (res.status === 200) {
        const decoded = jwt_decode(res.data);
      localStorage.setItem("hideZero", decoded.hideZeroStatus);
      document.querySelector("form").reset();
      sessionStorage.setItem("user", JSON.stringify(res.data));
        localStorage.removeItem("Pages");
        
        if (userposition.previous && userposition.previous === '/btrl-game') {
            navigate('/btrl-game'); // Set the desired page as the redirect target
           }else if (userposition.previous && userposition.previous === '/crorepati-lucky-draw') {
          navigate('/crorepati-lucky-draw'); // Set the desired page as the redirect target
        } else {
          navigate(`/${_redirect}`); // Set the default dashboard page as the redirect target
        }
      
      }
      else{
        swal(res.message);
      }
    } catch (err) {
      if (err) {
        //swal(res.message, res.data);
        swal(res.message?res.message:"Something went wrong. Try again.");
      }
    }

  }
  else{
    swal("Please enter a vaild google authentication code.");
  }
}
  const dmode = states.dmode;

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);
  return (
    <div className={dmode ? `${styles.signin_l} signPage ligtMode` : `${styles.signin_l} signPage ligtMode`}>
      <Navbar />

      <div className='locktag text-center'><i className="fa-solid fa-lock"></i>  URL Verification: <b><span className='greenText'>https://</span>www.btrlexchange.com</b></div>
      <div className={`${styles._signin}  container padSec padSecimporant`}>
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-12 col-md-6 col-xl-6 my_col_mobile">
              {twoFaStatus===true ?
                <form  className="flex-start column">
                <h2 className="text-start  titleh2">Verify 2FA Code</h2>
            
                    
                  <div className="form-group w-100 ">
                    <label className="text-start d-block">Google Authentication Code:</label>
                      <input type="text" placeholder="Google Authentication Code" autocomplete="off" name="tfa" onChange={e => setLCode(e.target.value)} key={"tfa"}/>
                      <button className={`${styles.signin_btn} btn`} onClick={tFAVerifyHandler} type="button">
                        <BsPersonCheckFill />
                        <span>Validate</span>
                      </button>
                    </div>
                </form>
              :
              
              <form onSubmit={handleSubmit} className="flex-start column">

                <h2 className="text-start titleh2">Sign In</h2>
                <h5>Doesn’t have an account yet? <Link to="/signup">Sign Up</Link></h5>
                <div className={`${styles.password} withIcon`}>
                  <div className="form-group w-100 ">
                    <label className="text-start d-block">Email Address:</label>
                    <div className="relative">
                      <input
                        onChange={(e) => getData("email", e.target.value)}
                        
                        type="email"
                        placeholder="Email Address*"
                      />
                      <button type="button" className={`send-otp-button flex-center`} onClick={handleSendOtp} disabled={otpSent}>
                        {/* {otpSent ? "OTP Sent" : "Send OTP"} */}
                        {otpButtonLabel}
                      </button>
                    </div>
                </div>
                <div className={styles.warning_note}>
                   {validate.email === false &&
                      formData.email.length > 0 &&<p>
                   
                      Please fill your valid email id
                  </p>}
                  {(_alert.email && !_alert.otp)&& <p>{_alert.email}</p>}
                  {(_alert.email && _alert.otp) && (
                  
                    <p >{_alert.email}</p>
                 
                )}
                </div>
                {otpSent && (
            <div className="form-group w-100 ">
              <label className="text-start d-block">OTP:</label>
              <input
                type="text"
                placeholder="Enter OTP"
                onChange={(e) => getData("otp", e.target.value)}
              />
            
            </div>
          )}
          {
            ((!otpSent && !_alert.email && _alert.otp))?
            <>
             <div className={styles.warning_note}>
              <p className="mb-0">Please send OTP first.</p>
            </div>
            </>
            :
            <>
             {(otpSent&& _alert.otp) && (
                  <div className={styles.warning_note}>

                    <p >{_alert.otp}</p>
                  </div>
               
              )}
            </>
          }
          {/* {(!_alert.email && _alert.otp) && (
            <div className={styles.warning_note}>
              <p className="mb-0">{_alert.otp}</p>
            </div>
          )} */}
          </div>
              <div className="form-group w-100 ">
                <label className="text-start d-block">Password:</label>
                <div className={`${styles.password} withIcon`}>
                  <input
                    onChange={(e) => getData("password", e.target.value)}
                    type={sp ? "text" : "password"}
                    placeholder="Password*"
                  />
                  <button
                    type="button"
                    onClick={spHandler}
                    className={`${sp ? styles.eyea : styles.eye} flex-center`}
                  >
                    <BsEyeFill />
                  </button>
              </div>
                </div>
                <div className={styles.warning_note}>
                  <p>
                    {/* validate.password === false &&
                      formData.password.length > 0 &&
                      "Please fill your valid password" */}
                  </p>
                </div>
                <div className={styles.warning_note}>
                  <p>{_alert.password}</p>
                </div>
                <ReCaptcha
                  checkCaptcha={setCaptcha}
                  reset={resetCaptcha}
                  setReset={setResetCaptcha}
                />
                <div className="bntandfor d-flex gap-3">
                <button
                  type="submit"
                  disabled={submitBtn}
                  className={`${styles.signin_btn} btn`}
                >
                  {submitBtn ? (
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  ) : (
                    "Login Now"
                  )}
                </button>
                <div className={`${styles.refer} flex-between sgn_bottom`}>
                  <Link to="/forget-password">Forgot password?</Link>
                </div>
              </div>
                {resendLink ?(
                  <div className="bntandfor d-flex gap-3">
                    <div className={`${styles.refer} flex-between`}>
                      <a href="#" onClick={resendLinkHandler}>Click Here To Resend Email Verification Link</a>
                    </div>
                  </div>
                ):(
                  <></>
                )}
              </form>
              }
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignIn;
