import React, { useContext, useState, useEffect } from "react";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import styles from "./Register.module.scss";
import wallet_i from "../../Assets/wallet_login_register.png";
import { BsEyeFill } from "react-icons/bs";
import {
  allLetter,
  sendGetRequest,
  sendRequest,
  vcpassword,
  vpassword,
} from "../../Helper/helper";
import validator from "validator";
import { Country, State } from "country-state-city";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import Tooltip from "@material-ui/core/Tooltip";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import ReCaptcha from "../../Components/ReCaptcha/ReCaptcha";

const Register = () => {
  const [sp, setSp] = useState(false);
  const [value, setValue] = useState();
  const [_states, set_states] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [sponser, setSponser] = useState();
  const [submitBtn, setSubmitBtn] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [_alert, set_alert] = useState({});
  const [formData, setFormData] = useState({
    country: "India", 
});
  const [scp, setScp] = useState(false);
  const [captcha, setCaptcha] = useState();
  const { states } = useContext(Context);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const navigate = useNavigate();

  const spHandler = () => (sp ? setSp(false) : setSp(true));
  const scpHandler = () => (scp ? setScp(false) : setScp(true));

  const errorHandler = (key, val) => {
    let change = errorMessage;
    change[key] = val;
    setErrorMessage({ ...change });
  };

  const referenceCheck = (value) => {
    if (!value.startsWith("BT")) {
      return false;
    }
    const numbersOnly = value.slice(2);
    if (!/^\d+$/.test(numbersOnly)) {
      return false;
    }
  
    return true;
  }

  const handleSendOtp = async () => {
    if (isValidPhoneNumber(formData.phone)) {
      const response = await sendRequest(
        { mobile: formData.phone },
        "send-reg-otp",
        "post"
      );
      if (response.status === 200) {
        swal("OTP sent to your mobile number!");
        setOtpSent(true);
      } else {
        swal(response.message || "Failed to send OTP.");
      }
    } else {
      setErrorMessage((prev) => ({ ...prev, phone: "Invalid phone number." }));
    }
  };

  const handleVerifyOtp = async () => {
    const response = await sendRequest(
      { mobile: value, otp },
      "verify-reg-otp",
      "post"
    );
    if (response.status === 200) {
      swal("OTP verified successfully!");
      setOtpVerified(true);
    } else {
      swal(response.message || "Invalid OTP.");
    }
  };
  const checkUserExists = async (type, value) => {
    try {
   //const conditionObj=type==="mobile"?{ type:"mobile",value: value }:{ type:"email",value: value };
   const conditionObj={ type:type,value: value };
      const response = await sendRequest(
        conditionObj,
        "check-user-exists",
        "post"
      );
      if (response.status === 200) {
        return { exists: false, error: null };
      } else if (response.status === 402) {
        return { exists: true, error: response.message }; 
      } else if (response.status === 401) {
        return { exists: false, error: response.data?.value || "Invalid input" };
      } else {
        return { exists: false, error: "Unexpected error occurred" }; 
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      return { exists: false, error: "Network error" }; 
    }
  };
  const handleEmailChange = async (email) => {
    setValue("email", email);
    if (!email.includes("@")) {
      errorHandler("email", "Invalid email format");
      return;
    }
    const { exists, error } = await checkUserExists("email", email);
    if (error) {
      errorHandler("email", error);
    } else if (exists) {
      errorHandler("email", "Email already exists");
    }else{
      errorHandler("email", "");
    }
  };
  const handlePhoneChange = async (phoneNumber) => {
   
    const { exists, error } = await checkUserExists("mobile", phoneNumber);
    if (error) {
     // setPhoneError(error);
     errorHandler("phone", error);
    } else if (exists) {
     // setPhoneError("Phone number already exists");
      errorHandler("phone", "Phone number already exists");

    }else{
      errorHandler("phone", "");
    }
  };
  const getData = async(key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
    set_alert({});

    if(key === "reference"){
      if(referenceCheck(val)){
      const resSponser = await sendRequest(
        { referenceid: val },
        "get-sponser-detail"
      );
      setSponser(resSponser.data ? resSponser.data : "Invalid reference id");
      }else{
        setSponser("Invalid reference id")
      }
    }

    if (key === "name") {
      if (!allLetter(val)) {
        errorHandler(key, "Please enter valid name");
      } else {
        errorHandler(key, "");
      }
    }
    if (key === "country") {
      errorHandler(key, "");
      errorHandler("phone", "");
    }
    if (key === "terms") {
      errorHandler(key, "");
    }
    if (key === "email") {
      if (!validator.isEmail(val)) {
        errorHandler(key, "Please enter valid email");
      } else {
        handleEmailChange(val)
       // errorHandler(key, "");
      }
    }
    if (key === "otp") {
      const otpRegex = /^\d{6}$/;
      if (!otpRegex.test(val)) {
        errorHandler("otp", "OTP must be a valid 6-digit numeric value");
      } else {
        errorHandler(key, "");
      }
    }
    if (key === "password") {
      if (!vpassword(val)) {
        errorHandler(
          key,
          <span>
            * Passwords must be at least 10 characters in length and
            <br />* a minimum of 1 lower case letter [a-z] and
            <br />* a minimum of 1 upper case letter [A-Z] and
            <br />* a minimum of 1 numeric character [0-9] and
            <br />* a minimum of 1 special characte{" "}
            {`#$@!%&*?`}
          </span>
        );
      } else {
        errorHandler(key, "");
        val === formData.cpassword && errorHandler("cpassword", "");
        val !== formData.cpassword &&
          errorHandler("cpassword", "Password is not matched");
      }
    }
    if (key === "cpassword") {
      if (!vcpassword(formData.password, val)) {
        errorHandler(key, "Password is not matched");
      } else {
        errorHandler(key, "");
      }
    }
    if (formData.country) {
      const getCstates = Country.getAllCountries();
      getCstates.map((value) => {
        if (value.name === formData.country) {
          set_states(State.getStatesOfCountry(value.isoCode));
          let change = formData;
          change.countryCode = value.isoCode;
          change.flag = value.flag;
          change.phoneCode = value.phonecode;
          setFormData({ ...change });
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (
      formData.name &&
      !errorMessage.name &&
      formData.email &&
      !errorMessage.email &&
      formData.password &&
      !errorMessage.password &&
      formData.country &&
      !errorMessage.country &&
      formData.phone &&
      !errorMessage.phone &&
      formData.terms === "on" &&
      !errorMessage.terms && ( (formData.otp && !errorMessage.otp) || formData.country!=="India")
    ) {
      setSubmitBtn(true);
      const newFormData= {
        name: formData.name,
        password: formData.password,
        email: formData.email,
        state: formData.state,
        country: formData.country,
        confirm_password: formData.cpassword,
        referenceid: formData.reference,
        city: formData.city,
        mobile: formData.phone,
        otp: formData.otp?formData.otp:'',
        "g-recaptcha-response": captcha,
      };
      const res = await sendRequest(
        newFormData,
        "signup",
        "post"
      );

      res && setSubmitBtn(false);
      setResetCaptcha(true);

      if (res.status === 200) {
        const _swal = await swal(res.message);
        if (_swal) {
          document.querySelector("form").reset();
          setFormData({});
          navigate("/signin");
        }
      } else if (res.status === 401) {
        const uniq = Object.keys(res.data);
        uniq.map((value) => {
          if (value === "email") {
            errorHandler(
              value,
              "Your email address has already been taken. Try logging in with the same email."
            );
          } else if (value === "mobile") {
            errorHandler("phone", "Your phone no has already been taken.");
          }
        });
        console.log(res, errorMessage);
      } else {
        console.log(res, errorMessage);
        swal(res.message);
      }
    } else {
      if (!formData.name) {
        errorHandler("name", "Please fill your name");
      }
      if (!formData.email) {
        errorHandler("email", "Please fill your email");
      }
      if (formData.country === undefined || formData.country.length === 0) {
        errorHandler("country", "Please Select your country");
      }
      if (!formData.phone) {
        errorHandler("phone", "Please fill your phone no");
      }
      if (!formData.otp && formData.country==="India") {
        errorHandler("otp", "Please fill your registration otp");
      }
      if (!formData.password) {
        errorHandler("password", "Please set your password");
      }
      if (!formData.cpassword) {
        errorHandler("cpassword", "Please confirm your password");
      }
      if (formData.terms !== "on" || !formData.terms) {
        errorHandler(
          "terms",
          "Please accept the Terms & Conditions and Privacy Policy"
        );
      }
    }
  };

  const dmode = states.dmode;

  useEffect(() => {
    (async () => {
      // window.scrollTo(0, 0)
      document.querySelector("form").reset();
      setFormData({});
      const res = await sendGetRequest("global");
      getData("reference", res.data.default_referalId);
      const resSponser = await sendRequest(
        { referenceid: res.data.default_referalId },
        "get-sponser-detail"
      );
      setSponser(resSponser.data);
      const _reference = window.location.search.split("=")[1];
      _reference && getData("reference", _reference);
    })();
  }, []);

  useEffect(() => {
    if (value) {
      const isValidp = isValidPhoneNumber(value) ? "true" : "false";
      errorHandler("mobile", "");
      if (isValidp === "true") {
       // errorHandler("phone", "");
        getData("phone", value);
        setOtpSent(false);
        handlePhoneChange(value)
      } else {
       
        formData.country &&
          formData.phone &&
          errorHandler("phone", "Please enter valid phone no");
      }

      if (!formData.country) {
        setValue("");
        getData("phone", "");
        errorHandler("country", "Please select country first");
        errorHandler("phone", "Please select country first");
      }
    }
  }, [value]);
  return (
    <div className={dmode ? `${styles.register_l} signPage ligtMode` : `${styles.register_l} signPage ligtMode`}>
      <Navbar />
      <div className='locktag text-center'><i className="fa-solid fa-lock"></i>  URL Verification: <b><span className='greenText'>https://</span>www.btrlexchange.com</b></div>
      <div className={`${styles._register}  container padSecimporant`}>
      <div className="row align-items-center_ justify-content-center">
      <div className="col-sm-6">
        <form onSubmit={handleSubmit} className=" column">

          <h2 className="text-start titleh2">Sign Up</h2>
          {/* <div className="row">
            <div > */}
            {/* <div className="col-sm-6"> */}

              <div class="form-group w-100 ">
                <label class="text-start d-block">Name:</label>
                  <input
                onChange={(e) => getData("name", e.target.value)}
                type="text"
                placeholder="Name*"
                  />
                </div>
                {errorMessage.name && (
                  <div className={styles.warning_note}>
                    {/* <p>{_alert.name}</p> */}
                      <p class="mb-0">{errorMessage.name}</p>
                  </div>
                )}
            {/* </div>
          <div > */}
          {/* <div className="col-sm-6"> */}

              <div class="form-group w-100 ">
                <label class="text-start d-block">Email Address* :</label>
          <input
            onChange={(e) => getData("email", e.target.value)}
            type="email"
            placeholder="Email Address*"
          />
        </div>
            {errorMessage.email && (
            <div className={styles.warning_note}>
              <p class="mb-0">{errorMessage.email}</p>
            </div>
            )}

          {/* </div>
          </div> */}
        <div class="form-group w-100 ">
          <label class="text-start d-block">Country :</label>
          <div className={styles.sbox}>
            <select
              className={styles.country}
              onChange={(e) => getData("country", e.target.value)} 
              value={formData.country}
              defaultValue="India"
            >
              {Country.getAllCountries().filter((option) => option.name === "India").map((option, index) => {
                return (
                  <option value={option.name} key={index} selected>
                    {option.name} {option.flag}
                  </option>
                );
              })} 
              
            </select>
          </div>
            {/* <select
              className={styles.country}
              onChange={(e) => getData("country", e.target.value)}
            >
              <option value={""}>Select Country*</option>
             
              {Country.getAllCountries().filter((option) => option.name !== "Pakistan").map((option, index) => {
                return (
                  <option value={option.name} key={index}>
                    {option.name} {option.flag}
                  </option>
                );
              })}
            </select> */}
          </div>
          {errorMessage.country && (
            <div className={styles.warning_note}>
              <p class="mb-0">{errorMessage.country}</p>
            </div>
          )}

          <div className={`${styles.sc} flex-between`}>
            <div className={styles.sbox}>
            <div class="form-group w-100 ">
              <label class="text-start d-block">State:</label>
              <select
                className={`${styles.country} w-100`}
                onChange={(e) => getData("state", e.target.value)}
              >
                <option value={""}>Select State*</option>
                {_states.map((option, index) => {
                  return (
                    <option value={option.name} key={index}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
            </div>

            <div className={styles.sbox}>
        <div class="form-group w-100 ">
          <label class="text-start d-block">City:</label>
            <input
              onChange={(e) => getData("city", e.target.value)}
              type="text"
              placeholder="City"
              className={` w-100 m-0`}
            />
          </div>
          </div>
          </div>
          <div className={`${styles.password} withIcon`}>
 
        <div class="form-group w-100 ">
          <label class="text-start d-block">Phone Number:</label>
          <div class="relative">
          <PhoneInput className="borderfullforget pl-2"
            placeholder="Enter phone number"
            // international
            value={value}
            // countryCallingCodeEditable={false}
            defaultCountry={formData.countryCode ? formData.countryCode : "IN"}
            onChange={setValue}
            onClick={(e) =>
              !formData.phone || formData.phone.length === 0
                ? e.target.setSelectionRange(
                    e.target.value.length,
                    e.target.value.length
                  )
                : ""
            }
            error={
              value
                ? isValidPhoneNumber(value)
                  ? undefined
                  : "Invalid phone number"
                : "Phone number required"
            }
          />
          {formData.country==="India" && (
            <button type="button" className={`send-otp-button flex-center`} onClick={handleSendOtp} disabled={otpSent}>
            {otpSent ? "OTP Sent" : "Send OTP"}
          </button>
          )}
        </div>
        </div>
          {errorMessage.phone && (
            <div className={styles.warning_note}>
              <p class="mb-0">{errorMessage.phone}</p>
            </div>
          )}
           </div>
          {otpSent && (
            <div class="form-group w-100 ">
              <label class="text-start d-block">OTP:</label>
              <input
                type="text"
                placeholder="Enter OTP"
                // onChange={(e) => setOtp(e.target.value)}
                onChange={(e) => getData("otp", e.target.value)}
              />
              {/* <button type="button" onClick={handleVerifyOtp}>
                Verify OTP
              </button> */}
            </div>
          )}
          {errorMessage.otp && (
            <div className={styles.warning_note}>
              <p class="mb-0">{errorMessage.otp}</p>
            </div>
          )}
          <div className="row">
            <div className="col-sm-6">

        <div class="form-group w-100 ">
          <label class="text-start d-block">Password:</label>
              {/* <Tooltip title="Password must be min 10 characher , 1 Upper case , 1 lower case and a special character"> */}
              <div className={`${styles.password} withIcon`}>
                <input
                  onChange={(e) => getData("password", e.target.value)}
                  type={sp ? "text" : "password"}
                  placeholder="Password*"
                />
                <button
                  type="button"
                  onClick={spHandler}
                  className={`${sp ? styles.eyea : styles.eye} flex-center`}
                >
                  <BsEyeFill />
                </button>
              </div>
              {/* </Tooltip> */}
            </div>
              {errorMessage.password && (
                <div className={styles.warning_note}>
                  <p class="mb-0">{errorMessage.password}</p>
                </div>
              )}
            </div>
            <div className="col-sm-6">

        <div class="form-group w-100 ">
          <label class="text-start d-block">Confirm Password:</label>
              <div className={`${styles.password} withIcon`}>
                <input
                  onChange={(e) => getData("cpassword", e.target.value)}
                  type={scp ? "text" : "password"}
                  placeholder="Confirm Password*"
                />
                <button
                  type="button"
                  onClick={scpHandler}
                  className={`${scp ? styles.eyea : styles.eye} flex-center`}
                >
                  <BsEyeFill />
                </button>
              </div>
            </div>
              {errorMessage.cpassword && (
                <div className={styles.warning_note}>
                  <p class="mb-0">{errorMessage.cpassword}</p>
                </div>
              )}

          </div>
        </div>
          {sponser && <h3 className={styles.sponser}>Sponser : {sponser}</h3>}
          <input
            onChange={(e) => {
              getData("reference", e.target.value.toUpperCase())
            }}
            style={{textTransform: "uppercase"}}
            defaultValue={formData.reference}
            type="text"
            placeholder="Reference id*"
          />

          <div className={`${styles.terms} flex-start`}>
            <input
              className={styles.box}
              type="checkbox"
              name="terms"
              onClick={(e) => getData("terms", e.target.checked ? "on" : "off")}
            />
            <p>
             {/*  I certify that I am 18 years of age or older, and I agree to the */}
              I certify that I am 18 years of age or older,  
              I will be self responsible for all my trade profit and losses and I agree to the 
              <a href="/terms-and-conditions"> Terms & Conditions</a> 
              , <a href="/refund-policy">Refund Policy</a>  , <a href="/risk-statement">Risk Statement</a> and{" "}
              <a href="/privacy-policy">Privacy Policy</a>.
            </p>
          </div>
          {errorMessage.terms && (
            <div className={styles.warning_note}>
              <p>{errorMessage.terms}</p>
            </div>
          )}

          <ReCaptcha
            checkCaptcha={setCaptcha}
            reset={resetCaptcha}
            setReset={setResetCaptcha}
          />
          <div className={`${styles.refer}  bntandfor d-flex gap-3 align-items-center`}>
            <button
              type="submit"
              disabled={submitBtn}
              className={`${styles.register_btn} btn`}
            >
              {submitBtn ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Register Now"
              )}
            </button>
            <Link to="/signin">I have an account ?</Link>
          </div>
        </form>
      </div>
</div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
