import React, { useEffect } from "react";
import styles from "./News.module.scss";

const News = () => {
  // useEffect(() => {
  //   const loadFacebookSDK = () => {
  //     if (!document.getElementById("facebook-jssdk")) {
  //       const script = document.createElement("script");
  //       script.id = "facebook-jssdk";
  //       script.src = "https://connect.facebook.net/en_US/sdk.js";
  //       script.async = true;
  //       script.defer = true;
  //       document.body.appendChild(script);

  //       script.onload = () => {
  //         if (window.FB) {
  //           window.FB.init({
  //             appId: "119894037854893", // Replace with your App ID
  //             xfbml: true,
  //             version: "v12.0", // Adjust to the latest stable version
  //           });

  //           // Parse XFBML again after initialization
  //           window.FB.XFBML.parse();
  //         }
  //       };
  //     }
  //   };

  //   loadFacebookSDK();
  // }, []);

  return (
    <section className={styles.news_l}>
      
      
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbitcoinregular&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=119894037854893"
        width="340"
        height="500"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
<div id="fb-root"></div>
    </section>
  );
};

export default News;


// import React, { useEffect } from "react";
//  import { Timeline } from 'react-twitter-widgets'
// /*import { TwitterTimelineEmbed } from "react-twitter-embed"; */
// import styles from "./News.module.scss";

// const News = ({ dmode }) => {

//   return (
//     <section className={styles.news_l}>
//      <div id="fb-root"></div>
//      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbitcoinregular&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=119894037854893" width="340" height="500"  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
     
//     </section>
//   );
// };

// export default News;

  /* useEffect(()=>{
    twttr.widgets.createTimeline(
      {
        sourceType: "profile",
        screenName: "TwitterDev"
      },
      document.getElementById("container")
    );

  },[]) */
