import React, { useState, useEffect } from "react";
import { fixedNumber, refreshToken, sendRequest } from "../../../Helper/helper";
import styles from "./BuyOrderChart.module.scss";

const BuyOrderChart = ({ bdata, trade,setBuyTrade, dmode, buyTrade, setBid,buyLoader, setBuyLoader }) => {
  const [ssize, setSsize] = useState(window.innerWidth);


  const smartTrade = (value) => {
    value.type="Sell";
    if(!buyTrade){
      setBid(value)
    }else{
      setBuyTrade(false)
      setTimeout(() => {
        setBid(value)
      }, 300);
    }
  }

  useEffect(() => {
    (async () => {
      window.addEventListener("resize", () => setSsize(window.innerWidth));
    })();
  }, []);
  //console.log("trade=>",trade);
  return (
    <section
      style={{
        display: buyTrade && ssize <= 750 ? "none" : "block",
        gridArea: buyTrade && ssize <= 1200 && ssize > 750 ? "3/1/4/2" : "",
        // gridArea: buyTrade && ssize <= 1050 && ssize > 750 ? "3/1/4/2" : "",
      }}
      className={`${styles.boc_l} latesthandle`}
    >
      <h1 className={styles.title}>Buy Order</h1>
       <div className='relative  custableresponsive table-responsive custableresponsive_height buysellOrder mt-15'>
       
      <table className={styles.c_table}>
        <thead className='custhead'>
        <tr>
          <th><div>
            Amount<span>({trade?.currency_symbol})</span>
          </div></th>
          <th><div>
            Price<span>({trade?.market_symbol})</span>
          </div></th>
          <th><div>
            Total<span>({trade?.market_symbol})</span>
          </div></th>
        </tr>
        </thead>
        <tbody className={styles.tbody}>

          {!buyLoader && ( bdata?.map((selld, i) => (
              <tr
                onClick={() => smartTrade(selld)}
                key={i}
                className={styles.tr}
              >
                <td>{fixedNumber(selld.qty, 12)}</td>
                <td className={`${styles.price} textsuccess`}>
                  {fixedNumber(selld.bid_price, 12)}
                </td>
                <td>{fixedNumber(selld.total, 12)}</td>
              </tr>
            )))}

        </tbody>
      </table>
      {buyLoader && (<span className="loader"></span>) }
    </div>
    </section>
  );
};

export default BuyOrderChart;
