import React,{useContext, useState, useEffect} from 'react';
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { MdPassword,MdCurrencyRupee,MdAccountBox,MdCurrencyExchange } from "react-icons/md";
import { FaClipboardList,FaHistory } from "react-icons/fa";
import { GrSecure, GrLogout } from "react-icons/gr";
import { NavLink as Link, useNavigate } from "react-router-dom";

import Context from '../../../Context/Context';
import { userFuncion } from '../../../Helper/helper';
import styles from "./Navbar.module.scss";
import jwt_decode from "jwt-decode";
const DropDownMenu = ({valways}) => {
  const getUser = sessionStorage.getItem("user");
 
  const { states, changeState } = useContext(Context);
    const [isDrop, setIsDrop] = useState(false);
    const [username, setUsername] = useState("User");
    const [profileImage, setProfileImage] = useState(null);
    useEffect(() => {
      if (getUser !== "" && getUser !== "false") {
        try {
          const DecodedUser = jwt_decode(getUser);
          if (DecodedUser) {
            //console.log("DecodedUser=>",DecodedUser);
            const finalSentence = DecodedUser?.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            setUsername(finalSentence)
            if(DecodedUser.profile_image){

              setProfileImage(DecodedUser.path+DecodedUser.profile_image)
            }
          }
          } catch (e) {
          }
        }
    
      },[])
    const navigate=useNavigate()
    
  return (
    <>
        <div style={{display : valways && 'flex' , marginLeft : valways && 0}} className={`${styles.menud} dropMenu`}>

            <span
              className='mrMain'
              to="#"
              onClick={()=>setIsDrop(true)}
             >
              <span className="mr">Hi, {username} <BiChevronDown /></span>
              <img src={profileImage ? profileImage : "/user.jpg"} className="img-fluid mrImg" />
              {/* <img src="/user.jpg" className="img-fluid mrImg" /> */}

            </span>
                {isDrop && <div style={{left : valways && 0 }} className={`${styles.box} dropMenuIn flex-center column`}>
<span
              className='mrMain'
              to="#"
              onClick={()=>setIsDrop(true)}
             >
              <img src={profileImage ? profileImage : "/user.jpg"} className="img-fluid mrImg" /> 
              {/* <img src="/user.jpg" className="img-fluid mrImg" />  */}
              <span className="mr">Hi, {username} </span>

            </span>
                {/* <Link onClick={()=>setIsDrop(false)} to="/personal-information"> Personal Information</Link> */}
                <Link onClick={()=>setIsDrop(false)} to="/profile"><i><MdAccountBox /></i> Profile</Link>
                <Link onClick={()=>setIsDrop(false)} to="/nominee"><i><MdAccountBox /></i> Nominee</Link>
                <Link onClick={()=>setIsDrop(false)} to="/pension-plan"><i><MdCurrencyRupee /></i> Pension Plan</Link>
                <Link onClick={()=>setIsDrop(false)} to="/pending-balance"><i><MdCurrencyRupee /></i> Pending Balance</Link>
                <Link onClick={()=>setIsDrop(false)} to="/change-password"><i><MdPassword /></i> Change Password</Link>
                <Link onClick={()=>setIsDrop(false)} to="/bank-details"><i><FaClipboardList /></i> Bank Details</Link>
                <Link onClick={()=>setIsDrop(false)} to="/history"><i><FaHistory /></i> History</Link>
                <Link onClick={()=>setIsDrop(false)} to="/security"><i><GrSecure /></i> Security</Link>
                <button style={{color: "#fff"}} onClick={()=>{setIsDrop(false)
                userFuncion(false)
                changeState(states.dmode, window.innerWidth, states.stokenbtn , states.buyTrade , false)
                navigate("/exchange")
                }} ><i><GrLogout /></i> Logout</button>
                </div>}
              </div>
              {isDrop && <div onClick={()=>setIsDrop(false)} className={styles._boxbg}></div>}
    </>
  )
}

export default DropDownMenu