import React from "react";
import { FaSearch,FaTimes,FaFilter } from 'react-icons/fa';
//width: 200px;
import styled from "styled-components";

//style={{marginBottom:'10px'}}
const FilterComponent = ({ filterText, onClear,onFilter }) => (
  <>
  <div className="d-flex align-items-center position-relative" >
    
    <span className="filter-icon position-absolute">
      {/* <i className="fa fa-filter"></i> */}
      <FaFilter />
    </span>

    
    <select
      id="filter"
      onChange={onFilter}
      className="w-20 form-control pl-4"
      data-filter={filterText}
      style={{ paddingLeft: '2.5rem' }} // Extra space for the icon
    >
      <option value="">Filter By Status</option>
      <option value="0">Pending</option>
      <option value="1">Received</option>
    </select>
  </div>

  {/* <div className='d-flex'>
    <select
      id="filter"
     
      onChange={onFilter}
      className='w-20 form-control'
      data-filter={filterText}
     
    >
      <option value="">Select Status...</option>
      <option value="0">Pending</option>
      <option value="1">Received</option>
    </select>
  </div> */}
  {/* <div className='d-flex'>
    <Input
      id="search"
      type="text"
      placeholder="Filter table data..."
      value={filterText}
      onChange={onFilter}
      className='w-100'
    />
  {(search!==""?
    <ClearButton onClick={onClear}><FaTimes /></ClearButton>
  :
    < SearchButton onClick={onSearch}><FaSearch /></SearchButton>
  )}
  </div> */}
   
   
  </>
);

export default FilterComponent;
