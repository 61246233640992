/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import styles from "./module.scss";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";

import PaginationBox from "./PaginationBox/PaginationBox.js";
import FilterComponent from "./FilterComponent.js"
import Context from "../../../Context/Context";
//import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars

import Table from 'react-bootstrap/Table';
import Modal from 'react-modal';

import {
  sendRequest,
  refreshToken,
  shortener
} from "../../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const SignupBonus = () => {
 
  const { states } = useContext(Context);
  const [allData, setAllData] = useState();
  const [activePage, setActivePage] = useState(localStorage.getItem("SignupBonus") && localStorage.getItem("SignupBonus")!==""  ? parseInt(localStorage.getItem("SignupBonus")):1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [activeValue, setActiveValue] = useState("SignupBonus");
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;

  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const handleClear = () => {
    if (filterText) {
     setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  }
  useEffect(()=>{
  
   const getSignupBonusData = async () => {
    const oohres = await sendRequest(
                          {
                            
                            start: activePage > 1 ? activePage * 10 - 10 : 0,
                            //start: 1,
                            limit: "10",
                             filter:filterText
                          },
                          "get-holding-signup-bonus"
                        );
    setAllData(oohres.data)
    refreshToken(oohres.refresh_token);
    setTotalRecord(oohres.totalRecords)
  }
  getSignupBonusData();
  },[activePage,filterText, resetPaginationToggle])
  const filterHandler=(e)=>{
    console.log("e value",e.target.value)
    setFilterText(e.target.value)
  }


// console.log("allData",allData);
  return (
    <div className={` ligtMode`}>
      <Navbar />
      <div className='container relative  pt-4'>
      <div className={` historyTable  p-relative`} >
        <div className="stakeMain ">
        <h1 className={` text-center mt-3 mb-3`}>
         Signup Bonus By Team
        </h1>
      </div>
        {/* <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} onSearch={ searchHandler} search={search}/> */}
        <FilterComponent onClear={handleClear} filterText={filterText} onFilter={ filterHandler} />
        <table className="table table-bordered">
        <thead>
          <tr className=" tableLook">
            <th>User ID</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allData && allData.length > 0 ? (
            allData.map((item, bi) => (
              <tr className=" tableLook" key={bi}>
                <td>
                  
                  BT{item.childid}
                </td>
                <td>
                  
                  {item.amount}
                </td>
                <td>
                 
                  {item.status === "0" ? "Pending" : "Received"}
                </td>
              </tr>
            ))
          ) : (
            <tr className=" tableLook" key="0">
              <td colSpan={3}>
                
                No Record Found!
              </td>
             
            </tr>
          )}
        </tbody>
      </table>

          
             
          <div className={` flex-between`}>
        <div></div>
        <div className=''>
          {allData && (
            <PaginationBox
              
              activePage={activePage}
              setActivePage={setActivePage}
              _totalPage={totalRecord}
            />
          )}
        </div>
      </div>
        </div>
      </div>
      <Footer />


    </div>


  );
};

export default SignupBonus;
