import React, { useContext, useState } from "react";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import wallet_i from "../../Assets/wallet_login_register.png";
import { BsEyeFill } from "react-icons/bs";
import { AiTwotoneLock } from "react-icons/ai";
import styles from "./ResetPassword.module.scss";
import { sendRequest, vcpassword, vpassword } from "../../Helper/helper";
import swal from "sweetalert";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { states } = useContext(Context);
  const [sp, setSp] = useState({});
  const [submitBtn, setSubmitBtn] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
  });
  const [validate, setValidate] = useState({
    password: "",
    cpassword: "",
  });

  const validateHandler = (key, val) => {
    let change = validate;
    change[key] = val;
    setValidate({ ...change });
  };

  const getData = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
    if (key === "password") {
      if (!vpassword(val)) {
        validateHandler(
          key,
          <span>
            * Passwords must be at least 10 characters in length and
            <br />* a minimum of 1 lower case letter [a-z] and
            <br />* a minimum of 1 upper case letter [A-Z] and
            <br />* a minimum of 1 numeric character [0-9] and
            <br />* a minimum of 1 special character{" "}
            {`#$@!%&*?`}
          </span>
        );
      } else {
        // validateHandler(key, "");
        // val === formData.cpassword && validateHandler("cpassword", "");
        // val !== formData.cpassword && validateHandler("cpassword", "Password is not matched");
        
        validateHandler(key, vpassword(val));
        validateHandler("cpassword", formData.password === formData.cpassword);

      }
      //validateHandler(key, vpassword(val));
      //validateHandler("cpassword", formData.password === formData.cpassword);
    }
    if (key === "cpassword") {
      validateHandler(key, formData.password === formData.cpassword);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hash = window.location.pathname.split("/")[2];
    getData("hash", hash);
    if (formData.password && formData.cpassword && hash) {
      console.log("validate.password => ",validate.password);
      console.log("validate.cpassword => ",validate.cpassword);
      if (validate.password && validate.cpassword) {
        setSubmitBtn(true);
        const res = await sendRequest(
          {
            password: formData.password,
            confirm_password: formData.cpassword,
            reset_password_hash: formData.hash,
          },
          "reset-password",
          "post"
        );
        res && setSubmitBtn(false);
        
        const _swal =  res.status !== 401?await swal(res.message):false;
        try {
          if (res.status === 200) {
            if (_swal) {
              document.querySelector("form").reset();
              navigate("/signin");
            }
          } else if (res.status === 401) {
            const uniq = Object.keys(res.data);
            uniq.map((value) => {
             // console.log(" value=>", value);
              //console.log(" res.data[value]=>", res.data[value]);
              validateHandler(
                value,
                res.data[value]
              );
            });
          } else {
            if (res.status === 402 && _swal) {
              navigate("/forget-password");
            }
          }
        } catch (err) {
          if (err) {
            if (res.status !== 401){

              swal(JSON.stringify(res.message));
            }
          }
        }
      } else {
        swal(
          "Please fill a valid password, and your new password and confirm password must be same !"
        );
      }
    } else {
      swal("Please fill all required fields !");
    }
  };

  const spHandler = (_val) => {
    let change = sp;
    change[_val] = sp[_val] ? false : true;
    setSp({ ...change });
  };

  const dmode = states.dmode;
  return (
    <div className={dmode ? `${styles.chpass_d} signPage darkMode` : `${styles.chpass_l} signPage ligtMode`}>
      <Navbar />
      <div className={`${styles._chpass} flex-between container`}>
        <form onSubmit={handleSubmit} className="flex-start column">
          {/* <h1>Reset Password</h1> */}
          <h2 className="text-start titleh2">Reset Password</h2>
          
            <div className={`${styles.password} flex-between bgin withIcon borderfullforget`}>
              <span className="flex-center ">
                <AiTwotoneLock />
              </span>
              <input
                onChange={(e) => getData("password", e.target.value)}
                required
                type={sp.np ? "text" : "password"}
                placeholder="New Password"
              />
              <button
                type="button"
                onClick={() => spHandler("np")}
                className={`${sp.np ? styles.eyea : styles.eye} flex-center`}
              >
                <BsEyeFill />
              </button>
            </div>
          <div className={styles.warning_note}>
            <p class="mt-3">
              {validate.password === false &&
                formData.password.length > 0 &&
                "Please fill your valid password"}

            {validate.password && (
              <p>{validate.password}</p>

          )}
            </p>
            {/*errorMessage.password && (
                <div className={styles.warning_note}>
                  <p class="mb-0">{errorMessage.password}</p>
                </div>
              )*/}
          </div>

          <div className={`${styles.password} flex-between  bgin withIcon borderfullforget`}>
            <span className="flex-center">
              <AiTwotoneLock />
            </span>
            <input
              onChange={(e) => getData("cpassword", e.target.value)}
              required
              type={sp.cp ? "text" : "password"}
              placeholder="Confirm Password"
            />
            <button
              type="button"
              onClick={() => spHandler("cp")}
              className={`${sp.cp ? styles.eyea : styles.eye} flex-center`}
            >
              <BsEyeFill />
            </button>
          </div>
          <div className={styles.warning_note}>
            <p>
              {validate.cpassword === false &&
                formData.cpassword.length > 0 &&
                "Password not match!"}
            </p>
            {validate.cpassword && (
              <p>{validate.cpassword}</p>

          )}
          </div>

          <button
            type="submit"
            disabled={submitBtn}
            className={`${styles.chpass_btn} btn`}
          >
            {submitBtn ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </form>
        <img src={wallet_i} alt="wallet" />
      </div>

      <Footer />
    </div>
  );
};

export default ResetPassword;
