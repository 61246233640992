import React,{useState , useEffect} from 'react';
import { fixedNumber, isMax, refreshToken, sendRequest } from '../../../Helper/helper';
import styles from './MarketHistory.module.scss';
import moment from 'moment';
const MarketHistory = ({dmode , trade , marketHistory}) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [newMarket, setNewMarket] = useState([]);

  const parseDuration = duration => {
    const unit = duration.charAt(duration.length - 1);
    const value = Number(duration.slice(0, -1));
    return { unit, value };
  };

  const formatDuration = duration => {
   //console.log("duration=>",duration);
    if(duration){
      const seconds = duration.seconds();
      const minutes = duration.minutes();
      const hours = duration.hours();
      const days = duration.days();
  
      if (days > 0) return `${days} D`;
      if (hours > 0) return `${hours} H`;
      if (minutes > 0) return `${minutes} M`;
      return `${seconds} S`;

    }
  };


 
  /* useEffect(() => {
    if(data){
      //const sortedData = [...data].sort((a, b) => a.time - b.time);
      const newMarket = [];
      data.reverse().map((value , i) => {
        newMarket.unshift(<tr key={i} className={styles.tr} >
          <td className={isMax("market_history" , parseFloat(value.price)) ? styles.pos : styles.neg}>{fixedNumber(value.price , 12)}</td>
          <td>{fixedNumber(value.amount , 12)}</td>
          <td>{value.time}</td>
        </tr>)
      })
      setNewMarket(newMarket)
    }
  }, [data]) */
  useEffect(() => {

    if(data){
      const orderedData = [...data].sort((a, b) => {
        
        const unitA =parseDuration(a.time).unit
        const unitB =parseDuration(b.time).unit
        const valueA =parseDuration(a.time).value
        const valueB =parseDuration(b.time).value
        // console.log("unitA =>",unitA);
        // console.log("valueA =>",valueA);
        // console.log("unitB =>",unitB);
        // console.log("valueB =>",valueB);
        if (unitA === unitB) {
          return valueA - valueB;
        } else if (unitA === 'S') {
          return -1;
        } else if (unitB === 'S') {
          return 1;
        }else if (unitA === 'M') {
          return -1;
        } else if (unitB === 'M') {
          return 1;
        } else if (unitA === 'D') {
          return -1;
        } else if (unitB === 'D') {
          return 1;
        } else {
          return -1;
        }
      });
      const newMarket = [];
      //console.log("orderedData=>",orderedData.reverse());
      //sortedData.reverse().map((value , i) => {
        //data.map((value , i) => {
          {/* <td className={isMax("market_history" , parseFloat(value.price)) ? styles.pos : styles.neg}>{fixedNumber(value.price , 12)}</td> */}
          orderedData.reverse().map((value , i) => {
            newMarket.unshift(<tr key={i} className={styles.tr} >
              <td className={isMax("market_history" , parseFloat(value.price)) ? "textsuccess" : "textdanger"}>{fixedNumber(value.price , 12)}</td>
              <td>{fixedNumber(value.amount , 12)}</td>
              <td>{value.time}</td>
            </tr>)
          })
      setNewMarket(newMarket)
    }
  }, [data])

  
  // useEffect(() => {
  //   if (newMarket) {
  //     const timeout = setTimeout(() => {
  //     setLoader(false); 
  //   }, 800);

  //   return () => clearTimeout(timeout); 
  //   }
  // }, [newMarket]);

  useEffect(() => {
    const interval = setInterval(() => {
      
      setData(prevData => {
        return prevData.map(item => {
          
          const newUnit = parseDuration(item.time)
          //console.log("newUnit=>",newUnit.unit);
          const newTime = newUnit.unit==="S"?moment.duration(newUnit.value, 'seconds'):(newUnit.unit==="M"?moment.duration(newUnit.value, 'minutes'):(newUnit.unit==="H"?moment.duration(newUnit.value, 'hours'):moment.duration(newUnit.value, 'days'))); //moment.duration()
          //console.log("newTime=>",newTime);
          const fTime=newTime.add(1, 'seconds');
          //console.log("fTime=>",fTime);
          return { ...item, time: formatDuration(fTime) };
        });
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);
 /*  useEffect(() => {
    //console.log("trade=>",trade);
    (async () => {
      const res = await sendRequest(
        { currency: trade?.currency_symbol, market: trade?.market_symbol , start : 0 , limit : 20  },
        "market-history"
      );
      setData(res.data ? res.data : []);
      res.refresh_token && refreshToken(res.refresh_token);
    })();
  }, [trade]); */


  useEffect(() => {
    //console.log("trade=>",trade);
    (async () => {
      if(trade?.currency_symbol && trade?.market_symbol){
        setLoader(true);
        const res = await sendRequest(
          { currency: trade?.currency_symbol, market: trade?.market_symbol , start : 0 , limit : 20  },
          "market-history"
        );
        setData(res.data ? res.data : []);
        setLoader(false);
        res.refresh_token && refreshToken(res.refresh_token);
      }
    })();
  }, [trade]);

  useEffect(() => {
    if(marketHistory && data && trade){
      if(marketHistory?.currency_symbol.toUpperCase() === trade?.currency_symbol.toUpperCase() && marketHistory?.market_symbol.toUpperCase() === trade?.market_symbol.toUpperCase()){
        setData([marketHistory,...data.reverse()])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketHistory , trade])
  
  return (
    <section 
    className={`${styles.mh_l} latesthandle`}
    >
      <h1 className={styles.title}>Market History</h1>
 <div className='relative custableresponsive table-responsive  custableresponsive_height mt-15'>
      <table className={styles.c_table}>
        <thead className='custhead'>
        <tr>
          <th>
            <div>Price<span>({trade?.market_symbol})</span></div>
          </th>
          <th>
          <div>Amount<span>({trade?.currency_symbol})</span></div>
          </th>
          <th>
          <div>Time</div>
          </th>
        </tr>
      </thead>
        <tbody className={styles.tbody}>
        {!loader && (
          newMarket?.map((value , i) => value)
        )}
        </tbody>
      </table>

       
       {loader && (<span className="loader"></span>) }
      </div>
    </section>
  )
}

export default MarketHistory