import React, { useContext, useState, useEffect } from "react";


import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import wallet_i from "../../Assets/wallet_login_register.png";
import { BsEyeFill } from "react-icons/bs";
/* import login from "../../../Assets/login.svg"; */
import login from "../../Assets/login.svg";
import styles from "./CompanyInfo.module.scss";
import { sendRequest, vpassword } from "../../Helper/helper";
import validator from "validator";
import swal from "sweetalert";
import jwt_decode from "jwt-decode";
import ReCaptcha from "../../Components/ReCaptcha/ReCaptcha";
import { Link, useNavigate } from "react-router-dom";
import { MdSecurity } from "react-icons/md";
import { BsPersonCheckFill } from "react-icons/bs"
const CompanyInfo = () => {
  
  return (
    <div className={`ligtMode`}>
      <Navbar />

        <div className='locktag text-center'><i className="fa-solid fa-lock"></i>  URL Verification: <b><span className='greenText'>https://</span>www.btrlexchange.com</b></div>
        <div className={`${styles._signin}  container padSec padSecimporant`}>
          <div class="InfoCom">
            
            <div class="text-center">
              <h1>Sunny Rana Technologies Private Limited</h1>
              <h2>Office: 701, Business Hub, Sector - 81, Greater Faridabad, Pin 121004</h2>
            </div>
            <div class="leftInfo">
              <h3><b>MRS.Rekha</b> </h3>
              <h3>H NO: Dantewada, Chhattisgarh - 494556</h3> 
              <h3>+91 1234567890</h3>
              <div class="space"></div>
              <h4>Certificate No: BT/123456</h4>
              <h4>Servicing Branch: BTRLEXCHANGE-Faridabad</h4>
              <h4>Broker: BT123456/Pankaj</h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th>Certificate No</th>
                    <td>BT123456</td>
                    <th>INT Rate</th>
                    <td>15.00% P.A</td>
                  </tr>
                  <tr>
                    <th>Acknowlegement No</th>
                    <td>SBI-307821</td>
                    <th>Date of Deposit</th>
                    <td>08/11/2024</td>
                  </tr>
                  <tr>
                    <th>Customer ID</th>
                    <td>Demo@gmail.com</td>
                    <th>Date of Maturity</th>
                    <td>08/11/2025</td>
                  </tr>
                  <tr>
                    <th>PAN</th>
                    <td>123456</td>
                    <th>Type of Deposit</th>
                    <td>Cash</td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>10,000.00</td>
                    <th>INT.PAY Frequency</th>
                    <td>P.A</td>
                  </tr>
                  <tr>
                    <th>Period</th>
                    <td>12 Months</td>
                    <th>INT.PAY Mode</th>
                    <td>NEFT</td>
                  </tr>
                  <tr>
                    <th>Senior Citizen Scheme</th>
                    <td>No</td>
                    <th>Maturity Instuctions</th>
                    <td>Full Payment on Maturity</td>
                  </tr>
                  <tr>
                    <th>Beneficarry Name</th>
                    <td>Rekha</td>
                    <th>TAX Status Type</th>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th>Customer Id TAX Status</th>
                    <td>No</td>
                    <th>Maturity Amount</th>
                    <td>11,500.00 RS</td>
                  </tr>
                  <tr>
                    <th>Nominee Detail</th>
                    <td>ABC</td>
                    <th>Nominee Documents</th>
                    <td>5252551518</td>
                  </tr>
                  <tr>
                    <th>Beneficarry Bank Detail</th>
                    <td colspan="3">State Bank of India, ***********1515, IFSCCODE: SBINO22818, ECSMICROCODE: NA</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 class="mt-2"><b>Terms & Conditions:</b>  If the payment is withdraw within 1 year then 5% penalty will be imposed.</h5>

            <div class="text-end">
              <img src="/latestimages/Screenshot 2024-12-20 173338.png" className="img-fluid sing" />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default CompanyInfo;
