import React, { useContext, useState, useEffect } from "react";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import wallet_i from "../../Assets/wallet_login_register.png";
import { AiOutlineMail } from "react-icons/ai";
import styles from "./ForgetPassword.module.scss";
import validator from "validator";
import { sendRequest } from "../../Helper/helper";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ReCaptcha from "../../Components/ReCaptcha/ReCaptcha";

const ForgetPassword = () => {
  const { states } = useContext(Context);
  const [captcha, setCaptcha] = useState();
  const [submitBtn, setSubmitBtn] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [validate, setValidate] = useState({
    email: "",
  });

  const validateHandler = (key, val) => {
    let change = validate;
    change[key] = val;
    setValidate({ ...change });
  };

  const getData = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
    if (key === "email") {
      validateHandler(key, validator.isEmail(val));
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate.email) {
      setSubmitBtn(true);
      const res = await sendRequest(
        {
          email: formData.email,
          "g-recaptcha-response": captcha,
        },
        "forgot-password",
        "post"
      );
      res && setSubmitBtn(false);
      setResetCaptcha(true);

      if (res.data === "Invalid captcha.") {
        console.log("should be captcha reset");
      }

      try {
        if (res.status === 200) {
          const _swal = await swal(res.message);
          if (_swal) {
            document.querySelector("form").reset();
            navigate("/signin");
          }
        } else {
          swal(res.message);
        }
      } catch (err) {
        if (err) {
          swal(JSON.stringify(res.message));
        }
      }
    } else {
      swal("Please fill your email !");
    }
  };

  const dmode = states.dmode;

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);

  return (
    <div  className={dmode ? `${styles.forpass_l} signPage ligtMode` : `${styles.forpass_l} signPage ligtMode`}>
      <Navbar />
      
      <div className='locktag text-center'><i className="fa-solid fa-lock"></i>  URL Verification: <b><span className='greenText'>https://</span>www.btrlexchange.com</b></div><div className={`${styles._forpass}  container padSecimporant`}>
        <div className="d-flex  justify-content-center">
        <form onSubmit={handleSubmit} className="column ">
          <h2 className="text-start  titleh2 mb-3">Forget Password</h2>
          <div className={`${styles.password} flex-between borderfullforget`}>
            <span className="flex-center">
              <AiOutlineMail />
            </span>
            <input
              onChange={(e) => getData("email", e.target.value)}
              required
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div
            style={{
              display:
                validate.email === formData.email
                  ? "none"
                  : validate.email === false && formData.email.length > 0
                  ? "block"
                  : "none",
            }}
            className={styles.warning_note}
          >
            <p>Please fill your valid email id</p>
          </div>
          <br/>
          <ReCaptcha
            checkCaptcha={setCaptcha}
            reset={resetCaptcha}
            setReset={setResetCaptcha}
          />
          <button
            type="submit"
            disabled={submitBtn}
            className={`${styles.forpass_btn} btn`}
          >
            {submitBtn ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ForgetPassword;
